export const Permissions = {
    /**
     * APPOINTMENT
     */
    APPOINTMENT_CREATE: 'APPOINTMENT_CREATE',
    APPOINTMENT_EDIT: 'APPOINTMENT_EDIT',
    APPOINTMENT_DECLINE: 'APPOINTMENT_DECLINE',
    APPOINTMENT_DELETE: 'APPOINTMENT_DELETE',
    APPOINTMENT_ADDUSER: 'APPOINTMENT_ADDUSER',
    APPOINTMENT_DELETEUSER: 'APPOINTMENT_DELETEUSER',
    APPOINTMENT_SELECTABLE: 'APPOINTMENT_SELECTABLE',

    /**
     * MEMBER
     */
    MEMBER_ACCEPT_REJECT: 'MEMBER_ACCEPT_REJECT',
    MEMBER_ROLE: 'MEMBER_ROLE',
    MEMBER_DELETE: 'MEMBER_DELETE',

    MEMBER_CONTRACT_CHANGE: 'MEMBER_CONTRACT_CHANGE',
    MEMBER_CONTRACT_MEMBERSHIP: 'MEMBER_CONTRACT_MEMBERSHIP',
    MEMBER_CONTRACT_FLEXIBLE_TICKET: 'MEMBER_CONTRACT_FLEXIBLE_TICKET',

    /**
     * TEMPLATE
     */
    TEMPLATE_CREATE: 'TEMPLATE_CREATE',
    TEMPLATE_EDIT: 'TEMPLATE_EDIT',
    TEMPLATE_DELETE: 'TEMPLATE_DELETE',

    /**
     * ROLE
     */
    ROLE_CREATE: 'ROLE_CREATE',
    ROLE_EDIT: 'ROLE_EDIT',
    ROLE_DELETE: 'ROLE_DELETE',

    /**
     * COMPANY
     */
    COMPANY_EDIT: 'COMPANY_EDIT',
    COMPANY_COLOR: 'COMPANY_COLOR',
    COMPANY_LOGO: 'COMPANY_LOGO',

    /**
     * PRODUCT
     */
    PRODUCT_CREATE: 'PRODUCT_CREATE',
    PRODUCT_EDIT: 'PRODUCT_EDIT',
    PRODUCT_DELETE: 'PRODUCT_DELETE',

    /**
     * STATISTICS
     */
    STATISTICS_AUDIT: 'STATISTICS_AUDIT',
    STATISTICS_APPOINTMENT: 'STATISTICS_APPOINTMENT',
    STATISTICS_MEMBER: 'STATISTICS_MEMBER',
    STATISTICS_BOOKING: 'STATISTICS_BOOKING',
    STATISTICS_CASHFLOW: 'STATISTICS_CASHFLOW',
};

export const AdminTab = [
    Permissions.MEMBER_ACCEPT_REJECT,
    Permissions.MEMBER_ROLE,
    Permissions.MEMBER_DELETE,

    Permissions.MEMBER_CONTRACT_CHANGE,
    Permissions.MEMBER_CONTRACT_MEMBERSHIP,
    Permissions.MEMBER_CONTRACT_FLEXIBLE_TICKET,

    Permissions.TEMPLATE_CREATE,
    Permissions.TEMPLATE_EDIT,
    Permissions.TEMPLATE_DELETE,

    Permissions.ROLE_CREATE,
    Permissions.ROLE_EDIT,
    Permissions.ROLE_DELETE,

    Permissions.COMPANY_EDIT,
    Permissions.COMPANY_COLOR,
    Permissions.COMPANY_LOGO,

    Permissions.PRODUCT_CREATE,
    Permissions.PRODUCT_EDIT,
    Permissions.PRODUCT_DELETE,
];

export const AppointmentTab = [
    Permissions.APPOINTMENT_CREATE,
    Permissions.APPOINTMENT_EDIT,
    Permissions.APPOINTMENT_DELETE,
    Permissions.APPOINTMENT_ADDUSER,
    Permissions.APPOINTMENT_DELETEUSER,
    Permissions.APPOINTMENT_SELECTABLE,
];
