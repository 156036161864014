import dayjs from 'dayjs';
import _ from 'lodash';

const getCountDownText = differenceInSeconds => {
    if (differenceInSeconds <= 60) {
        // 60 seconds
        return `Termin ist in ${differenceInSeconds} Sekunden buchbar`;
    } else if (differenceInSeconds <= 60 * 5) {
        // 5 mintues
        let min = Math.floor(differenceInSeconds / 60);
        let sec = differenceInSeconds - min * 60;
        sec = _.padStart(sec, 2, 0);
        return `Termin ist in ${min}:${sec} Minuten buchbar`;
    } else {
        // more than 5 mintues
        let humanize = dayjs
            .duration(differenceInSeconds, 'seconds')
            .humanize(true);
        return `Der Termin ist ${humanize} buchbar`;
    }
};

function getBookingStatus(membership, appointment) {
    if (appointment.is_decline === true) {
        return 'DECLINE';
    }

    if (isAppointmentInPast(appointment)) {
        return 'PAST';
    }

    if (isAppointmentAllreadyBooked(appointment, membership)) {
        if (isAppointmentCancelable(appointment)) {
            return 'CANCELED';
        } else {
            return 'CANCELED_NOT_POSSIBLE';
        }
    }

    if (hasUserFlexProduct(membership)) {
        if (!hasUserUnitsAvailable(membership)) {
            return 'NO_UNITS_AVAILABLE';
        }
    }

    if (isAppointmentBookable(appointment)) {
        if (isClassFulll(appointment)) {
            return 'ACCEPT_WAITLIST';
        }

        return 'ACCEPT';
    }

    if (isAppointmentInFuture(appointment)) {
        return 'FEATURE';
    }

    return 'NOT_BOOKABLE';
}

function getColor(status) {
    let mappingTexts = {
        DECLINE: 'grey_light',
        CANCELED: 'red',
        CANCELED_NOT_POSSIBLE: 'grey_light',
        NO_UNITS_AVAILABLE: 'grey_light',
        ACCEPT: 'green',
        ACCEPT_WAITLIST: 'yellow',
        NOT_BOOKABLE: 'grey_light',
        PAST: 'grey_light',
        FEATURE: 'grey_light',
    };

    if (!mappingTexts[status]) {
        throw Error(`GetColor Status not found: ${status}`);
    }

    return mappingTexts[status];
}

const getButtonText = (status, appointment) => {
    let mappingTexts = {
        DECLINE: 'Termin ist abgesagt',
        CANCELED: 'Stornieren',
        CANCELED_NOT_POSSIBLE: 'Stornierung nicht mehr möglich',
        NO_UNITS_AVAILABLE: 'Keine buchbare Einheiten verfügbar',
        ACCEPT: 'Teilnehmen',
        ACCEPT_WAITLIST: 'Teilnehmen (Warteliste eintragen)',
        NOT_BOOKABLE: 'Termin ist nicht buchbar',
    };

    if (mappingTexts[status]) {
        return mappingTexts[status];
    }

    let differenceInMinutes, humanize;

    if (status === 'PAST') {
        differenceInMinutes = dayjs(appointment.appointment).diff(
            new Date(),
            'minutes',
        );
        humanize = dayjs
            .duration(differenceInMinutes, 'minutes')
            .humanize(true);

        return `Der Termin hat ${humanize} begonnen`;
    }

    if (status === 'FEATURE') {
        differenceInMinutes = dayjs(appointment.bookable_from_date).diff(
            new Date(),
            'minutes',
        );
        humanize = dayjs
            .duration(differenceInMinutes, 'minutes')
            .humanize(true);
        return `Der Termin ist ${humanize} buchbar`;
    }

    throw Error(`Status not found: ${status}`);
};

function getColorForActionButton(appointment, membership) {
    if (appointment.is_decline === true) {
        return 'grey_light';
    }

    if (isAppointmentBookable(appointment) === false) {
        // console.log('isAppointmentBookable', appointment)
        return 'grey_light';
    }

    if (isAppointmentAllreadyBooked(appointment, membership)) {
        if (isAppointmentCancelable(appointment)) {
            return 'red';
        } else {
            return 'grey_light';
        }
    }

    if (isClassFulll(appointment)) {
        return 'yellow';
    }

    if (hasUserFlexProduct(membership)) {
        if (!hasUserUnitsAvailable(membership)) {
            return 'grey_light';
        }
    }

    return 'green';
}

function isAppointmentInPast(appointment) {
    return dayjs(appointment.appointment).isBefore();
}

function isAppointmentInFuture(appointment) {
    return dayjs(appointment.appointment).isAfter(new Date());
}

function isClassFulll(appointment) {
    if (_.isEmpty(appointment.participants)) {
        return false;
    }

    // filter canceled participants
    let participants = _.filter(
        appointment.participants,
        item => item.canceled_at === null,
    );

    let maxP = parseInt(appointment.max_participant, 0);

    // infinity participants
    if (maxP === 0) {
        return false;
    }
    return _.size(participants) >= maxP;
}

function getIconNameByColorName(colorName) {
    // bookable
    if (colorName === 'green') {
        return 'calendar-check';
        // return 'calendar-check-o';
    }
    // allready booked => ready for cancel
    if (colorName === 'red') {
        return 'calendar-remove';
        // return 'calendar-times-o';
    }

    // class full
    if (colorName === 'yellow') {
        return 'calendar-text';
        // return 'calendar-o';
    }

    // not bookable
    return 'calendar-clock';
    // return 'clock-o';
}

function isAppointmentAllreadyBooked(appointment, membership) {
    // superadmin has no membership
    if (
        !membership ||
        !membership.user_company ||
        !membership.user_company.id
    ) {
        return false;
    }

    let userId = membership.user_company.id;
    if (!userId) {
        return false;
    }

    let participants = appointment.participants.find(
        participant => participant.user_id === userId,
    );
    if (!participants) {
        return false;
    }

    return participants.canceled_at === null;
}

function isAppointmentCancelable(appointment) {
    let date = dayjs(appointment.appointment);
    let differenceInMinutes = date.diff(new Date(), 'minutes');

    //  Appointment is in the past
    if (differenceInMinutes <= 0) {
        return false;
    }

    // It's not cancelable (cancel_till)
    if (differenceInMinutes <= appointment.cancel_till) {
        return false;
    }

    return true;
}

function isAppointmentBookable(appointment) {
    // bookable at
    if (dayjs(appointment.bookable_from_date).isAfter()) {
        return false;
    }

    // appointment has not start
    if (dayjs(appointment.appointment).isBefore()) {
        return false;
    }

    return true;
}

function hasUserFlexProduct(membership) {
    // has any product
    if (!membership || !membership.product) {
        return false;
    }

    return membership.product.type === 'FLEXIBLE_TICKET';
}

function hasUserUnitsAvailable(membership) {
    return membership.available_units > 0;
}

function isDeclined(appointment) {
    return appointment.is_decline;
}

async function isAppointmentAllreadyCanceled(appointment, userId) {
    let participants = appointment.participants.find(
        participant => participant.user_id === userId,
    );
    if (!participants) {
        return true;
    }

    return participants.canceled_at !== null;
}

module.exports = {
    getBookingStatus,
    getColor,
    getButtonText,
    isAppointmentInPast,
    isAppointmentInFuture,
    getColorForActionButton,
    isAppointmentBookable,
    isAppointmentAllreadyBooked,
    isAppointmentCancelable,
    getIconNameByColorName,
    isClassFulll,
    hasUserFlexProduct,
    hasUserUnitsAvailable,
    isDeclined,
    isAppointmentAllreadyCanceled,
    getCountDownText,
};
