import { useQuery } from '@apollo/client';
import { GET_MEMBERSHIPS } from '@/components/graphql/memberships';
import { useAuthContext } from './authContext';
import { useRouter } from 'next/router';

export default function useMembership() {
    let { isAuthenticated, auth } = useAuthContext();
    const router = useRouter();
    let { companyId } = router.query;

    const { loading, error, data, refetch } = useQuery(GET_MEMBERSHIPS, {
        variables: {
            where: {
                companies: {
                    id: { _eq: companyId },
                },
                user_id: {
                    _eq: auth?.uid,
                },
            },
        },
        skip: !companyId || !isAuthenticated(),
    });
    let membership = null;
    if (data && data?.memberships && data.memberships[0]) {
        membership = data?.memberships[0];
    }

    return [membership, loading, refetch, error];
}
