import { isUserAdmin } from '@/components/helper';
import _ from 'lodash';

/**
 * @return {boolean}
 */
export default function hasPermission(user, membership, permissions) {
    // user maybe not loaded
    if (user === undefined || user === null) {
        return false;
    }

    // super admin
    if (isUserAdmin(user)) {
        return true;
    }

    // membership maybe not loaded
    if (membership === undefined || membership === null) {
        return false;
    }

    // no permissions exists
    let userPermissions = membership.role.permissions;
    if (!userPermissions || userPermissions.length === 0) {
        return false;
    }

    // convert to array
    if (typeof permissions === 'string') {
        permissions = [permissions];
    }

    // check
    return permissions.some(permissionName =>
        userPermissions.includes(permissionName),
    );
}
