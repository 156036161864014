import CompanyLogo from '@/components/ui/company/CompanyLogo';
import { Text, Link, Heading, Flex, Box } from '@chakra-ui/react';
import Icon from '@mdi/react';
import { mdiOpenInNew } from '@mdi/js';

export default function CompanyHead({ company, right }) {
    if (!right) {
        return <Content company={company} mt={6} />;
    }

    return (
        <Flex
            mt={6}
            alignItems="center"
            direction={['column', 'column', 'row']}
        >
            <Content company={company} w="full" />
            <Box ml={[0, 0, 4]} mt={[4, 4, 0]}>
                {right}
            </Box>
        </Flex>
    );
}

function Content({ company, ...props }) {
    return (
        <Flex alignItems="center" {...props}>
            <CompanyLogo company={company}></CompanyLogo>
            <Box ml="4">
                <Heading
                    as="h1"
                    size="lg"
                    // px={4}
                    // py={5}
                    borderBottom="1px"
                    borderColor="gray.200"
                >
                    {company.name}
                </Heading>
                <Link
                    display="flex"
                    alignItems="center"
                    fontSize="sm"
                    color="gray.600"
                    mt="0"
                    href={getGoogleMapsLink(company)}
                    isExternal
                >
                    <Text as="span" mr="1">
                        {company.street} {company.postalcode} {company.city}
                    </Text>
                    <Icon path={mdiOpenInNew} size={0.7} />
                </Link>
            </Box>
        </Flex>
    );
}

/**
 * https://developers.google.com/maps/documentation/urls/guide#directions-action
 * @param item
 * @returns {string}
 * @private
 */
function getGoogleMapsLink(item) {
    let q = encodeURIComponent(
        [item.street, item.postalcode, item.city].join('+'),
    );

    return 'https://www.google.com/maps/search/?api=1&query=' + q;
}
