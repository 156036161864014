import gql from 'graphql-tag';

const GET_COMPANY = gql`
    query GetCompany($id: String!) {
        companies_by_pk(id: $id) {
            id
            master_company_id
            status
            name
            description
            phone
            email
            privacy_url
            logo_url
            homepage
            companyname
            street
            postalcode
            city
            created_at
            updated_at
        }
    }
`;

const GET_COMPANIES = gql`
    query GetCompanies {
        companies {
            id
            name
            master_company_id
            logo_url
            phone
            postalcode
            privacy_url
            status
            street
            homepage
            email
            description
            companyname
            city
        }
    }
`;

module.exports = {
    GET_COMPANY,
    GET_COMPANIES,
};
// export default { GET_ROLES }
