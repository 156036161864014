import {
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
} from '@chakra-ui/react';

export default function DrawerComponent({
    title,
    children,
    isOpen,
    onClose,
    size = 'md',
    ...props
}) {
    return (
        <Drawer
            isOpen={isOpen}
            placement="right"
            onClose={onClose}
            size={size}
            autoFocus={false}
        >
            <DrawerOverlay>
                <DrawerContent>
                    <DrawerHeader>{title}</DrawerHeader>
                    <DrawerCloseButton />
                    <DrawerBody mb="3">{children}</DrawerBody>
                </DrawerContent>
            </DrawerOverlay>
        </Drawer>
    );
}
