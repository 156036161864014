import React from 'react';
import { Flex, Heading, Box, Text } from '@chakra-ui/react';

const Card = ({
    children,
    title,
    subtitle,
    headRight,
    titleColor = 'gray.500',
    headRightMultiline = false,
    footer,
    ...props
}) => {
    return (
        <Box bg="white" rounded="md" {...props}>
            {title && (
                <Flex
                    px={4}
                    py={5}
                    borderBottom="1px"
                    borderColor="gray.200"
                    align={
                        headRightMultiline
                            ? ['space-between', 'center']
                            : 'center'
                    }
                    justifyContent="space-between"
                    direction={headRightMultiline ? ['column', 'row'] : 'row'}
                >
                    <Box>
                        <Heading
                            as="h2"
                            size="md"
                            color={titleColor}
                            textDecoration="none"
                            fontWeight="normal"
                            mt="0"
                            // w="full"
                        >
                            {title}
                        </Heading>
                        <Text mt="0" fontSize="xs">
                            {subtitle}
                        </Text>
                    </Box>
                    {headRight}
                </Flex>
            )}

            <Box px="4" pb="5">
                {children}
            </Box>

            {footer}
        </Box>
    );
};

export default Card;
