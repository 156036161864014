import { gql } from '@apollo/client';

// const GET_APPOINTMENTS = gql`
//     query GetAppointmentsQl(
//         $limit: Int!
//         $offset: Int!
//         $company_id: String!
//         $appointment: timestamptz_comparison_exp = {}
//     ) {
//         appointments(
//             limit: $limit
//             offset: $offset
//             order_by: { appointment: desc }
//             where: {
//                 company_id: { _eq: $company_id }
//                 appointment: $appointment
//             }
//         ) {
//             id
//             master_company_id
//             company_id
//             name
//             description
//             appointment
//             max_participant
//             is_decline
//             bookable_from
//             bookable_from_date
//             bookable_till
//             cancel_till
//             created_at
//             decline_message
//             duration
//             firestore_id
//             trainer {
//                 id
//                 gender
//                 username
//                 picture_url
//             }
//             participants {
//                 id
//                 status
//                 canceled_at
//                 updated_at
//                 created_at
//                 guest_gender
//                 guest_userid
//                 guest_username
//             }
//         }
//     }
// `

const GET_APPOINTMENTS = gql`
    query GetAppointmentsQl(
        $limit: Int = null
        $offset: Int = null
        $where: appointments_bool_exp = {}
        $order_by: [appointments_order_by!]
    ) {
        appointments(
            limit: $limit
            offset: $offset
            where: $where
            order_by: $order_by
        ) {
            id
            master_company_id
            company_id
            name
            description
            appointment
            max_participant
            min_participant
            is_decline
            bookable_from
            bookable_from_date
            bookable_till
            cancel_till
            created_at
            decline_message
            duration
            show_canceluser
            notification
            firestore_id
            trainer_id
            trainer {
                id
                gender
                username
                picture_url
            }
            participants {
                id
                master_company_id
                appointment_id
                status
                type
                user_id
                guest_gender
                guest_userid
                guest_username
                booked_at
                canceled_at
                created_at
                updated_at
                user {
                    id
                    gender
                    username
                    picture_url
                }
            }
        }
    }
`;

const GET_APPOINTMENT_SINGLE = gql`
    query GetAppointmentSingle($id: bigint = "") {
        appointments_by_pk(id: $id) {
            id
            master_company_id
            company_id
            name
            description
            appointment
            max_participant
            min_participant
            is_decline
            bookable_from
            bookable_from_date
            bookable_till
            cancel_till
            created_at
            decline_message
            duration
            firestore_id
            show_canceluser
            notification
            trainer_id
            trainer {
                id
                gender
                username
                picture_url
            }
            participants {
                id
                master_company_id
                appointment_id
                status
                type
                user_id
                guest_gender
                guest_userid
                guest_username
                booked_at
                canceled_at
                created_at
                updated_at
                user {
                    id
                    gender
                    username
                    picture_url
                }
            }
        }
    }
`;

const GET_APPOINTMENTS_SIMPLE = gql`
    query GetAppointmentsSimple(
        $company_id: String!
        $appointment: timestamp_comparison_exp = {}
    ) {
        appointments_simple: appointments(
            where: {
                company_id: { _eq: $company_id }
                appointment: $appointment
            }
        ) {
            id
            appointment
        }
    }
`;

const COUNT_APPOINTMENT = gql`
    query CountAppointment(
        $company_id: String!
        $appointment: timestamp_comparison_exp = {}
    ) {
        appointments_aggregate(
            where: {
                company_id: { _eq: $company_id }
                appointment: $appointment
            }
        ) {
            aggregate {
                count
            }
        }
    }
`;

const SEND_MESSAGE_APPOINTMENT = gql`
    mutation SendMessage($appointment_id: Int!, $message: String!) {
        appointment_message(arg: {appointment_id: $appointment_id, message: $message}) {
        success
        }
    }
`;

module.exports = {
    GET_APPOINTMENTS,
    GET_APPOINTMENT_SINGLE,
    GET_APPOINTMENTS_SIMPLE,
    COUNT_APPOINTMENT,
    SEND_MESSAGE_APPOINTMENT
};
