import { useQuery } from '@apollo/client';
import { GET_COMPANY } from '@/components/graphql/companies';
import { useRouter } from 'next/router';

export default function useCompany() {
    const router = useRouter();
    let { companyId } = router.query;

    const { loading, error, data, refetch } = useQuery(GET_COMPANY, {
        variables: {
            id: companyId,
        },
        skip: !companyId,
    });

    let company = data ? data?.companies_by_pk : undefined;

    return [company, loading, refetch, error];
}
