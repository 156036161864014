import React from 'react';
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Button,
    AlertDialogCloseButton,
} from '@chakra-ui/react';

const ConfirmDialog = ({ isOpen, onClose, action }) => {
    const cancelRef = React.useRef();

    return (
        <AlertDialog
            motionPreset="slideInBottom"
            leastDestructiveRef={cancelRef}
            onClose={onClose}
            isOpen={isOpen}
            isCentered
        >
            <AlertDialogOverlay />

            <AlertDialogContent>
                <AlertDialogHeader>Bestätigung</AlertDialogHeader>
                <AlertDialogCloseButton />
                <AlertDialogBody>
                    Soll die Aktion wirklich durchgeführt werden?
                </AlertDialogBody>
                <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={onClose}>
                        Nein
                    </Button>
                    <Button colorScheme="red" ml={3} onClick={action}>
                        Ja
                    </Button>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
};

export default ConfirmDialog;
