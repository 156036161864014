import gql from 'graphql-tag';

// const GET_MEMBERSHIP = gql`
//     query GetMembership($company_id: String!, $user_id: String!) {
//         memberships(
//             where: {
//                 companies: { id: { _eq: $company_id } }
//                 user_id: { _eq: $user_id }
//             }
//         ) {
//             id
//             master_company_id
//             product_id
//             role {
//                 id
//                 name
//                 slug
//                 permissions
//                 firestore_id
//             }
//             available_units
//             enable_reminder_email
//             enable_reminder_push
//             enable_waitlist_reminder_email
//             enable_waitlist_reminder_push
//             hide_not_bookable_appointments
//             start_contract
//             end_contract
//             start_membership
//             created_at
//             updated_at
//             companies {
//                 id
//                 name
//                 phone
//                 postalcode
//                 master_company_id
//                 logo_url
//                 homepage
//                 email
//                 description
//                 companyname
//                 city
//                 privacy_url
//                 status
//                 street
//                 updated_at
//                 created_at
//             }
//         }
//     }
// `

const GET_MEMBERSHIPS = gql`
    query GetMemberships($where: memberships_bool_exp = {}) {
        memberships(where: $where) {
            id
            master_company_id
            user_id
            product_id
            available_units
            enable_reminder_email
            enable_reminder_push
            enable_waitlist_reminder_email
            enable_waitlist_reminder_push
            hide_not_bookable_appointments
            signing_contract
            start_contract
            end_contract
            start_membership
            created_at
            updated_at
            role {
                id
                name
                slug
                permissions
                firestore_id
            }
            product {
                id
                master_company_id
                type
                title
                type
                tax
                product_number
                gross
                units_bookable
                payment_rhythm
                duration
                cancelation_period
                automatic_contract_renewal
                net_formated
                net_amount
                gross_formated
                gross_amount
            }
            companies {
                id
                name
                phone
                postalcode
                master_company_id
                logo_url
                homepage
                email
                description
                companyname
                city
                privacy_url
                status
                street
                updated_at
                created_at
            }
            user_company {
                id
                username
                gender
                first_name
                last_name
                email
                birthdate
                phone
                picture_url
            }
        }
    }
`;

// const GET_MEMBERSHIP_BY_MCID = gql`
//     query GetMembershipByMcId($mc_id: String!) {
//         memberships(where: { master_company_id: { _eq: $mc_id } }) {
//             id
//             start_membership
//             user_id
//             user_company {
//                 first_name
//                 last_name
//             }
//             role {
//                 name
//             }
//         }
//     }
// `

const UPDATE_MEMBERSHIP_SETTINGS = gql`
    mutation UpdateMembershipSettings(
        $id: bigint = ""
        $_set: memberships_set_input = {}
    ) {
        update_memberships_by_pk(pk_columns: { id: $id }, _set: $_set) {
            id
        }
    }
`;

module.exports = {
    // GET_MEMBERSHIP,
    GET_MEMBERSHIPS,
    UPDATE_MEMBERSHIP_SETTINGS,
    // GET_MEMBERSHIP_BY_MCID,
};
