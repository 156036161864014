import React from 'react';
import Image from 'next/future/image';
import { getStorageUrlPublic } from '@/components/helper';
import { Center, Text } from '@chakra-ui/react';

function getLocalDefaultImage(user) {
    let src = '/assets/images/member/nophoto_male.png';

    if (user.gender === 'female') {
        src = '/assets/images/member/nophoto_female.png';
    }

    return src;
}

function defaultLogo(company, width, height) {
    return (
        <Center
            width={width + 'px'}
            height={height + 'px'}
            bg="gray.800"
            rounded="md"
            p="3"
        >
            <Text
                mt="0"
                color="brand.orange"
                fontWeight="bold"
                textAlign="center"
                textTransform="uppercase"
                fontSize="sm"
                // fontSize={company.name.length > 14 ? 'xs' : 'sm'}
                noOfLines={3}
            >
                {company.name}
            </Text>
        </Center>
    );
}

const CompanyLogo = ({ company, width = 96, height = 96 }) => {
    let src;

    if (company.logo_url) {
        src = getStorageUrlPublic(company.logo_url);
    } else {
        return defaultLogo(company, width, height);
    }

    return (
        <Image
            src={src}
            alt={company.name}
            width={width}
            height={height}
            className="rounded-md"
        />
    );
};

export default CompanyLogo;
